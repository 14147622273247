import React from 'react';
import Highlighter from './Highlighter';
import { parseJwt } from '../../utils/utils';
import { getToken } from '../../config/cookiesInfo';

import {
  getHighlightText,
  postHighlightText,
  updateHighlightText,
} from '../../Redux/APIs/TextHighlighter/TextHighlighterAction';


function TextHighlighter(props) {
  const user_id = parseJwt(getToken())?.user_id;
  const { text, module_id, module_type } = props;
  let sampleData = [

    {
      "id": 1301,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 9,
      "endindex": 17,
      "is_removed": false,
      "is_public": false,
      paragraph_id: "content",
      "text_bg_color": "#9dd676",
      "created_at": "2024-05-08T19:08:20.029072+05:30",
      "updated_at": "2024-05-08T19:08:20.029104+05:30",
      "user": 9526
    },
    {
      "id": 1286,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 0,
      "endindex": 12,
      "is_removed": false,
      "is_public": false,
      paragraph_id: "content",
      "text_bg_color": "#f69929",
      "created_at": "2024-05-08T16:15:58.933701+05:30",
      "updated_at": "2024-05-08T16:15:58.933728+05:30",
      "user": 9526
    },
    {
      "id": 1301,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 90,
      "endindex": 120,
      "is_removed": false,
      "is_public": false,
      "paragraph_id": "p_2",
      "text_bg_color": "#9dd676",
      "created_at": "2024-05-08T19:08:20.029072+05:30",
      "updated_at": "2024-05-08T19:08:20.029104+05:30",
      "user": 9526
    },
    {
      "id": 1306,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 132,
      "endindex": 140,
      "is_removed": false,
      paragraph_id: "content",
      "is_public": false,
      "text_bg_color": "#f56a73",
      "created_at": "2024-05-09T12:17:19.885219+05:30",
      "updated_at": "2024-05-09T12:17:19.885243+05:30",
      "user": 9526
    },
    {
      "id": 1309,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 100,
      "endindex": 111,
      paragraph_id: "content",
      "is_removed": false,
      "is_public": false,
      "text_bg_color": "#9dd676",
      "created_at": "2024-05-09T12:24:56.135980+05:30",
      "updated_at": "2024-05-09T12:24:56.136009+05:30",
      "user": 9526
    },
    {
      "id": 1326,
      "note": "DUMMY NOTE",
      "module_type": "coi_schedule",
      "module_id": 5,
      "startindex": 1182,
      paragraph_id: "content",
      "endindex": 1222,
      "is_removed": false,
      "is_public": false,
      "text_bg_color": "#f56a73",
      "created_at": "2024-05-10T12:24:46.438407+05:30",
      "updated_at": "2024-05-10T12:24:46.438439+05:30",
      "user": 9526
    }
    //   {
    //     "id": 1323,
    //     "note": "DUMMY NOTE",
    //     "module_type": "judgement",
    //     "module_id": 985,
    //     "startindex": 4138,
    //     "endindex": 4233,
    //     "is_removed": false,
    //     "is_public": false,
    // paragraph_id: "content",
    //     "text_bg_color": "#9dd676",
    //     "created_at": "2024-05-10T11:33:29.192757+05:30",
    //     "updated_at": "2024-05-10T11:33:29.192788+05:30",
    //     "user": 9526
    // }
  ]

  const [highlightTextList, setHighlightTextList] = React.useState([])

  const onPostHighlightText = (data) => {

    let payloadData = {
      user: user_id,
      module_id: module_id,
      module_type: module_type,
      endindex: data?.endIndex,
      text_bg_color: data?.color,
      startindex: data?.startIndex,
      note: data?.noteText
    }

    postHighlightText(payloadData)
      .then((res) => {
        let resData = res?.data
        setHighlightTextList((previous) => [...previous, resData])
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onUpdateHighlightText = (payloadData) => {
    updateHighlightText(payloadData.id, payloadData)
      .then((res) => {
        let resData = res?.data
        console.log("resData of UpdataHighlight", resData)

        onGetHighlightText()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onGetHighlightText = () => {
    getHighlightText(module_id, module_type)
      ?.then((res) => {
        let dataList = res?.data
        let filterData = dataList.filter((data) => data.paragraph_id !== null)
        setHighlightTextList(filterData);
        // console.log("filterData:",filterData)
      })
      .catch((err) => {
        console.log(err);
      });
  }


  React.useEffect(() => {
    if (user_id) {
      onGetHighlightText()
    }
  }, [module_id, user_id])

  return (
    <Highlighter
      module_id={module_id}
      textToHighlight={text}
      listHighlightedData={highlightTextList}
      elementId={`highlighter-text-${module_id}`}
      onHighlightChange={(highlightedText) => { onPostHighlightText(highlightedText) }}
      onHighlightUpdate={(updateHighlightedText) => { onUpdateHighlightText(updateHighlightedText) }}
    />
  );
}
export default TextHighlighter;

