
// import { FETCH_KNOWLEDGEGRAPH_SUCCESS, FETCH_KNOWLEDGEGRAPH_FAILURE } from './KnowledgeGraphTypes'
import { HOST } from '../../../config/config'
import axios from 'axios'
import { getToken } from '../../../config/cookiesInfo'



export const fetchSpecificKnowledgeGraph = (pathname) => {

    // console.log('data-send==>', pathname)
//    console.log( `token==> ${getToken()}`);

    return new Promise((resolve, reject) => {
        axios.get(`${HOST}get_knowledgegraph/?pathname=${pathname}`, {
            headers: {
                "Authorization": `token ${getToken()}`
            },
        })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
}

export const postContributeKnowledgeGraph = (props_data) => {

    // console.log('data-send==>', props_data);
    // console.log('token==>', `token ${getToken()}`)

    return new Promise((resolve, reject) => {
        axios(`${HOST}contribute/`,{
            method: 'post',
            headers: {
                "Authorization": `token ${getToken()}`
            },
            data: props_data
        })
        .then((res) => {
            resolve(res);
            // console.log('postContributeKnowledgeGraph', res)
        }).catch((err) => {
            reject(err)
            // console.log('postContributeKnowledgeGraph', err)
        });
    })
};






// var config = {
    //     method: 'post',
    //     url: `${HOST}test/`,
    //     headers: {
        //         'Authorization': `token ${getToken()}`
        //     },
        //     params: data
        //     console.log(config)
        //     axios(config)
        //         .then((res) => {
            //             resolve(res);
            //             console.log('postContributeKnowledgeGraph', res.data)
            //         }).catch((err) => {
                //             reject(err)
                //             console.log('postContributeKnowledgeGraph', err)
                //         });
                // })
                
            


                
                // export const fetchKnowledgeGraphListSuccess = (data) => {
//     return {
//         type: FETCH_KNOWLEDGEGRAPH_SUCCESS,
//         payload: data
//     }
// }
// export const fetchKnowledgeGraphListFailure = (error) => {
//     return {
//         type: FETCH_KNOWLEDGEGRAPH_FAILURE,
//         payload: error
//     }
// }

// export const fetchKnowledgeGraphList = () => {
//     return async (dispatch) => {
//         // console.log('dsdsdsdsd');
//         try {
//             // let language_type = store.getState().toggleLanguage.currentLanguageType;
//             const res = await axios.get(`${HOST}`);
//             // console.log(res);
//             let array = res.data;
//             array.sort((a, b) => {
//                 return a.id - b.id
//             })
//             // console.log(arr);
//             // setData(array)
//             dispatch(fetchKnowledgeGraphListSuccess(array));
//             // console.log(arr);
//             // return arr;
//         }
//         catch (e) {
//             console.log(e);
//             dispatch(fetchKnowledgeGraphListFailure(e));

//         }

//     }
// }
