import React from 'react';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { getLoginStatus } from '../../config/cookiesInfo';
import { CustomCommentIcon, CustomHighlightIcon, CustomDeleteIcon } from './Icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { removeUnWantedSpan, onHighlightSelection, getSelectionFromOffsets, getOffsetsFromSelection } from './helper';

const useStyles = makeStyles((theme) => ({
    textContent: {
        cursor: 'auto',
        marginTop: 15,
        fontWeight: 400,
        lineHeight: 1.43,
        marginBottom: 15,
        whiteSpace: 'pre-wrap',
        letterSpacing: '0.01071em',
        fontFamily: `Roboto,Helvetica,Arial,sans-serif`,
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.up("xs")]: {
            fontSize: 15,
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: 16,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 17,
        },
    },
}));

const Highlighter = ({ module_id, listHighlightedData = [], textToHighlight, onHighlightChange, onHighlightUpdate, elementId }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [updateData, setUpdateData] = React.useState();
    const [paragraphId, setParagraphId] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    const handlePopoverOpen = (event, data) => {
        setUpdateData(data);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onHandleHighlightChange = async (data) => {
        // console.log("data in onHandleHighlightChange==>", data)
        if (updateData) {
            const newUpdateData = {
                ...updateData,
                ...data,
                text_bg_color: data.color,
                note: data.noteText // Overriding note key in updateData
            };
            applyHighlights([newUpdateData])
            onHighlightUpdate({
                ...newUpdateData,
                text_bg_color: data.is_removed ? updateData.text_bg_color : data.color

            })
        }
        else {
            onHighlightChange(
                {
                    color: data.color,
                    endIndex: data.end,
                    startIndex: data.start,
                    noteText: data.noteText,
                    selectedText: data.text,
                    paragraphId: paragraphId,
                }
            );
        }
    };



    const onHandleHighlightUpdate = (data) => {
        onHighlightUpdate(data)
    };

    // const applyHighlights = (data) => {
    //     for (const option of data) {
    //         const { is_removed, startindex, endindex, text_bg_color, paragraph_id } = option;
    //         const node = document.getElementById(paragraph_id);

    //         if (!node) return null
    //         getSelectionFromOffsets(startindex, endindex, node);
    //         let selection = window.getSelection();
    //         const range = selection.getRangeAt(0);
    //         const text = selection.toString();
    //         if (!is_removed) {

    //             onHighlightSelection(selection, text_bg_color, (event) => handlePopoverOpen(event, option), () => handlePopoverClose(anchorEl), paragraph_id);
    //             selection.removeAllRanges();
    //         } else {
    //             if (text.length > 0) {
    //                 const spans = node.querySelectorAll('span');
    //                 spans.forEach(span => {
    //                     // Remove the span tag while keeping its text content
    //                     if (text === span.innerHTML) {
    //                         span.outerHTML = span.innerHTML;
    //                     } else {
    //                         const textNode = document.createTextNode(text);
    //                         range.deleteContents();
    //                         range.insertNode(textNode);
    //                     }
    //                 });
    //             }
    //             removeUnWantedSpan(paragraph_id)
    //             selection.removeAllRanges();

    //         }
    //     }
    // };

    const applyHighlights = (data) => {
        const node = document.getElementById(elementId);
        if (!node) return null;

        for (const option of data) {
            const { is_removed, startindex, endindex, text_bg_color } = option;
            getSelectionFromOffsets(startindex, endindex, node);
            let selection = window.getSelection();

            if (!selection.rangeCount) continue;

            const range = selection.getRangeAt(0);
            const text = selection.toString();

            if (!is_removed) {
                // Add highlight
                onHighlightSelection(
                    selection,
                    text_bg_color,
                    (event) => handlePopoverOpen(event, option),
                    () => handlePopoverClose(anchorEl),
                    "content",
                    option
                );
                selection.removeAllRanges();
            } else {
                // Remove highlight
                const spans = node.querySelectorAll('span');
                spans.forEach(span => {
                    if (span.textContent.includes(text)) {
                        const parent = span.parentNode;

                        const textNode = document.createTextNode(span.textContent);
                        parent.replaceChild(textNode, span);

                        removeUnWantedSpan(elementId);
                    }
                });
                selection.removeAllRanges();
            }
        }
    };


    const handleSelection = () => {
        const selection = window.getSelection();
        const text = selection.toString();

        if (selection && text.length > 0 && text !== ' ') {
            const range = selection.getRangeAt(0);

            // checking if the selected range spans multiple nodes
            if (!isRangeInsideSingleNode(range)) {
                // return without modifying the DOM
                return;
            }

            if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
                const span = document.createElement('span');
                handlePopoverOpen({ currentTarget: span });
                range.surroundContents(span);

                const parentElementWithId = findParentWithId(span);
                if (parentElementWithId) {
                    const parentId = parentElementWithId.id;
                    setParagraphId(parentId);
                }
            } else {
                // handling nested HTML structure
                const documentFragment = range.extractContents();
                const span = document.createElement('span');
                span.appendChild(documentFragment);
                range.insertNode(span);
                handlePopoverOpen({ currentTarget: span });

                const parentElementWithId = findParentWithId(span);
                if (parentElementWithId) {
                    const parentId = parentElementWithId.id;
                    setParagraphId(parentId);
                }
            }
        }
    };

    const isRangeInsideSingleNode = (range) => {
        const startNode = range.startContainer.parentNode;
        const endNode = range.endContainer.parentNode;

        // checking if the start and end nodes are the same
        return startNode === endNode;
    };

    const findParentWithId = (element) => {
        let parent = element.parentElement || element.parentNode;

        while (parent) {
            if (parent.id) {
                return parent;
            }
            parent = parent.parentElement || parent.parentNode;
        }

        return null;
    };


    React.useEffect(() => {
        applyHighlights(listHighlightedData);
    }, [listHighlightedData]);

    React.useEffect(() => {
        setAnchorEl(null);
    }, [module_id])

    return (
        <>
            <LoginRequiredModal
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div>
                <MouseOverPopover
                    anchorEl={anchorEl}
                    updateData={updateData}
                    onHandleHighlightChange={onHandleHighlightChange}
                    onHandleHighlightUpdate={onHandleHighlightUpdate}
                    handlePopoverClose={() => handlePopoverClose(anchorEl)}
                    onSelectedTextPosition={() => {

                        if (!getLoginStatus()) {
                            setShowModal(true);
                            setAnchorEl(null);
                            return null;
                        }
                        const textNode = document.getElementById(paragraphId);
                        try {
                            let result = getOffsetsFromSelection(textNode);

                            if (!result || result.start === undefined || result.end === undefined) {
                                console.log('Invalid selection position');
                                return {
                                    startIndex: null,
                                    endIndex: null,
                                    selectedText: ''
                                };
                            }

                            return {
                                endIndex: result.end,
                                startIndex: result.start,
                                selectedText: result.text || ''
                            };
                        } catch (error) {
                            console.log('Error getting selection position:', error);
                            return {
                                startIndex: null,
                                endIndex: null,
                                selectedText: ''
                            };
                        }
                    }}

                />
                <p className={classes.textContent} id={elementId} onMouseUp={handleSelection}>
                    {textToHighlight}
                </p>
            </div>
        </>
    );
};

const LoginRequiredModal = ({ showModal, setShowModal }) => {
    const history = useHistory();

    const handleLogin = () => {
        setShowModal(false);
        history.push("/login", {
            popToBack: true,
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <Dialog
            open={showModal}
            onClose={handleCancel}
            aria-labelledby="login-required-title"
            aria-describedby="login-required-description"
            maxWidth="xs"
            fullWidth
            TransitionComponent={Fade}
            keepMounted
        >
            <DialogTitle id="login-required-title">Login Required</DialogTitle>
            <DialogContent>
                <DialogContentText id="login-required-description">
                    You need to log in to perform this action. Do you want to proceed to the login screen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleLogin} color="primary" autoFocus>
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MouseOverPopover = ({ updateData, anchorEl, handlePopoverClose, onHandleHighlightChange, onHandleHighlightUpdate, onSelectedTextPosition }) => {
    const open = Boolean(anchorEl);
    return (
        <Popover
            open={open}
            elevation={0}
            anchorEl={anchorEl}
            id="mouse-over-popover"
            onClose={handlePopoverClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <ToolBar
                updateData={updateData}
                onSelectedTextPosition={onSelectedTextPosition}
                onHandleHighlightChange={onHandleHighlightChange}
                onHandleHighlightUpdate={onHandleHighlightUpdate}
                handlePopoverClose={() => { handlePopoverClose() }}
            />
        </Popover>
    );
};

export default Highlighter;


const ToolBar = ({ updateData, handlePopoverClose, onHandleHighlightChange, onSelectedTextPosition }) => {
    const [note, setNote] = React.useState('');
    const [position, setPosition] = React.useState({
        endIndex: null,
        startIndex: null,
        selectedText: null,
    });
    const [color, setColor] = React.useState(null);

    const onSaveHighlightText = async (data) => {

        if (updateData) {
            if (updateData.text_bg_color !== data.color) {
                onUpdateHighlightText(data)
            }
        } else {
            let { startIndex, endIndex, selectedText } = position;
            await onHandleHighlightChange({
                ...data,
                end: endIndex,
                start: startIndex,
                text: selectedText,
            });
        }
        if (handlePopoverClose) {
            handlePopoverClose(false);
        }
    };

    const onUpdateHighlightText = async (data) => {
        await onHandleHighlightChange(data);
        if (handlePopoverClose) {
            handlePopoverClose(false);
        }
    };

    const onSaveNotes = async (data) => {
        if (updateData) {
            if (updateData.note !== data.noteText || updateData.text_bg_color !== data.color) {
                onUpdateHighlightText(data)
            }
        } else {
            let { startIndex, endIndex, selectedText } = position;
            await onHandleHighlightChange({
                ...data,
                end: endIndex,
                start: startIndex,
                text: selectedText,
                color: data.color === "#fffff" ? "#fefe54" : data.color,
            });
        }
        if (handlePopoverClose) {
            handlePopoverClose(false);
        }
    };

    const setSelectedTextPosition = () => {
        let isTextSelected = window.getSelection().toString()
        if (!isTextSelected) return;

        let selectedPosition = onSelectedTextPosition();

        if (selectedPosition) {
            setPosition({
                endIndex: selectedPosition.endIndex,
                startIndex: selectedPosition.startIndex,
                selectedText: selectedPosition.selectedText,
            });
        } else {
            console.log('No valid text position found');
        }
    };


    React.useEffect(() => {
        if (updateData) {
            setNote(updateData.note);
            setColor(updateData.text_bg_color);
        }
    }, [updateData])

    return (
        <React.Fragment>
            <div className='toolbar-container'>
                <div className='toolbar-wrapper'>

                    <div className='toolbox-icon-wrapper'>

                        <ColorPicker
                            color={color ? color : '#ffffff'}
                            onClickColor={() => {
                                if (!updateData) {
                                    setSelectedTextPosition();
                                }
                            }}
                            onSave={(newColor) => {
                                onSaveHighlightText({
                                    color: newColor,
                                    is_removed: false,
                                })
                            }}
                            onCancel={() => {
                                if (handlePopoverClose) {
                                    handlePopoverClose(false);
                                }
                            }}
                        />
                    </div>
                    <div className='toolbox-icon-wrapper'>

                        <CommentBox
                            note={note}
                            setNote={setNote}
                            onClickHighlight={() => {
                                if (!updateData) {
                                    setSelectedTextPosition();
                                }
                            }}

                            // onCancel={(text) => {
                            //     onSaveNotes({
                            //         noteText: text,
                            //         is_removed: false,
                            //         color: color ? color : '#ffffff',
                            //     });
                            // }}

                            onSave={(text) => {
                                onSaveNotes({
                                    noteText: text,
                                    is_removed: false,
                                    color: color ? color : '#fefe54',
                                });
                            }}
                        />
                    </div>
                    {Boolean(updateData) &&
                        <div className='toolbox-icon-wrapper'>
                            <button className='icon-button' onClick={() => onSaveHighlightText({
                                color: 'white',
                                is_removed: true,
                            })}>
                                <CustomDeleteIcon />
                            </button>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};


const CommentBox = ({ note, setNote, onSave, onCancel, onClickHighlight }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    let btnDisabled = note === '';
    return (
        <React.Fragment>
            <button className='icon-button' onClick={(event) => { getLoginStatus() && handleClick(event); onClickHighlight() }}>
                <CustomCommentIcon />
            </button>
            <Popover
                open={open}
                id="comment-box"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ minHeight: 150, minWidth: 300, padding: 10 }}>
                    <textarea rows="4" cols="50" className='custom-textarea' value={note} onChange={(event) => setNote(event.target.value)} />
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <button className='custom-button' onClick={(event) => { handleClick(event) }}>Cancel</button>
                        <button disabled={btnDisabled} className={btnDisabled ? 'button-disabled' : 'custom-button'} style={{ marginLeft: 15 }} onClick={() => { handleClick(); onSave(note) }}>Save</button>

                    </div>
                </div>
            </Popover>
        </React.Fragment>
    );
};

const ColorPicker = ({ color, onClickColor, onSave, onCancel }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const colorList = [
        '#f56a73',
        '#f69929',
        '#fefe54',
        '#9dd676',
        '#9bffff',
        '#b7a3ff',
    ]
    return (
        <React.Fragment>

            <button className='icon-button'
                onClick={(event) => { getLoginStatus() && handleClick(event); onClickColor() }}>
                <CustomHighlightIcon color={color} />
            </button>
            <Popover
                open={open}
                id="comment-box"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ width: '100px', height: '60px', background: '#ffffff' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {colorList.map((color, index) => {
                            return (
                                <div key={index}
                                    style={{ width: '20px', height: '20px', background: color, margin: '5px', borderRadius: '4px', cursor: 'pointer' }}
                                    onClick={() => { onSave(color); handleClick(); }}>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <button className='custom-button' onClick={(event) => { handleClick(event); onCancel() }}>Cancel</button>
                        <button disabled={!color} className={color ? 'button-disabled' : 'custom-button'} style={{ marginLeft: 15 }} onClick={() => { handleClick(); onSave() }}>Save</button>

                    </div> */}
                </div>
            </Popover>
        </React.Fragment>
    )
}
