import React from 'react';
import { useFormik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import { get_google_login } from '../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Box, Button } from '@material-ui/core';
import { loginUser } from '../../Redux/APIs/Login/LoginActions';
import FullScreenContent from '../MainContent/FullScreenContent';
import { getLoginStatus, setLoginStatus, setStaffStatus, setToken, setUserEmail, setUserId, setUsername } from '../../config/cookiesInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.primary.main,//color: 'red',

        },
        '& .MuiOutlinedInput-root': {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 45,
            },
            '& fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,


            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                //borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,
            },
        },
    },

    boxContainer: {
        borderRadius: 7,
        backgroundColor: 'white',
        // border:'3px solid black',
        margin: 'auto',
        maxWidth: 380,

        // minHeight:410,

        [theme.breakpoints.up('xs')]: {
            width: '85%',
            paddingBottom: 50,

        },
        [theme.breakpoints.down('sm')]: {
            // minHeight:390,
            paddingBottom: 30,

        },

    },
    content: {
        width: '100%',
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 25,
        }
    },
    container: {
        align: 'center',
    },
    formStyle: {
        display: 'grid',
        margin: 'auto',
        maxWidth: 280,
        [theme.breakpoints.up('xs')]: {
            width: '85%',
        }
    },
    txtPassword: {
        marginTop: 15,
    },
    loginButton: {
        marginTop: 20,
    },
    errorMessage: {
        color: 'red',
        marginBottom: 0,
    },
}));

const ConnectWithSocialAccount = () => {

    const classes = useStyles();
    const initialValues = {
        email: '',
        password: '',
    };

    const validate = values => {
        let errors = {};

        if (!values.email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email format'
        }

        if (!values.password) {
            errors.password = 'Required'
        }
        return errors;
    }

    const onSubmit = (values) => {

        loginUser(values)
            .then((res) => {
                if (res.data.success !== true) {
                    alert('Login unsuccessful ' + res.data.status);
                } else {
                    setToken(res.data.data.access_token);
                    setLoginStatus(true);
                    setUsername(res.data.data.user.name);
                    setUserEmail(res.data.data.user.email);
                    setStaffStatus(res.data.data.user.is_staff)
                    setUserId(res.data.data.user.id)
                    onClickedGoogleLogin();
                }
            })
            .catch((error) => {
                console.log("Error while connecting account", error);
            })
    }

    const onClickedGoogleLogin = () => {
        const link = document.createElement("a");
        link.href = get_google_login();
        document.body.appendChild(link);
        link.click();
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });

    return (
        <div>
            {getLoginStatus() ? (
                <Redirect to={{ pathname: '/' }} />
            ) : (
                <FullScreenContent>
                    <div className={classes.container}>
                        <div className={classes.content}>
                            <Box style={{ paddingTop: 20 }} className={classes.boxContainer}>
                                <form className={classes.formStyle}
                                    noValidate
                                    onSubmit={formik.handleSubmit}
                                    autoComplete="off">

                                    <Box sx={{ mb: 2 }}>
                                        <Alert severity="info" sx={{}}>
                                            We've found an existing account with the email address associated with your social account.<br /> To link the two accounts,please login with the email and password associated with your existing account.
                                        </Alert>
                                    </Box>
                                    <TextField
                                        classes={{
                                            root: classes.root
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        id="email" label="Email" type="email" name='email' variant="outlined"
                                    />
                                    {formik.touched.email && formik.errors.email ? <p className={classes.errorMessage}>{formik.errors.email}</p> : null}

                                    <TextField
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                        id="password"
                                        label="Password"
                                        type="password"
                                        name='password'
                                        variant="outlined"
                                        classes={{
                                            root: classes.root
                                        }}
                                        className={classes.txtPassword}
                                    />
                                    {formik.touched.password && formik.errors.password ? <p className={classes.errorMessage} style={{ marginBottom: -7 }}>{formik.errors.password}</p> : null}

                                    <Button
                                        className={classes.loginButton}
                                        type='submit'
                                        variant="contained"
                                        color="primary"
                                        disableElevation>
                                        Connect
                                    </Button>
                                </form>
                            </Box>
                        </div>
                    </div>
                </FullScreenContent>
            )}
        </div>
    )
}

export default ConnectWithSocialAccount;
