import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.jpg'
import {
    // AppBar,
    // Toolbar,
    Typography,
    // IconButton,
    // Box, 
    Divider,
    // Paper,
    Drawer,
    List,
    // Button,
    ListItemIcon,
    // ListItemText,
    ListItem,
    // Icon, 
} from '@material-ui/core';

// import BookmarkIcon from '@material-ui/icons/Bookmark';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import HelpIcon from '@material-ui/icons/Help';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { toggleLanguage } from '../../Redux/ToggleLanguage/ToggleLanguageActions';
import { openRightSidebar } from '../../Redux/OpenRightSidebar/OpenRightSidebarActions'

import { getLoginStatus, clearCookie, getUserEmail, getUsername } from '../../config/cookiesInfo'
// import Cookies from 'js-cookie'
import { connect } from 'react-redux'
// import { TramRounded } from '@material-ui/icons';
import firebase from '../../config/firebase-config'

import { useHistory } from 'react-router-dom';
import { SupervisedUserCircleOutlined, EventNote } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    list: {

        // width: 300,
        [theme.breakpoints.up("xs")]: {
            width: 250,
        },
        [theme.breakpoints.up("sm")]: {
            width: 280,
        },
        [theme.breakpoints.up("md")]: {
            width: 290,
        },
        [theme.breakpoints.up("lg")]: {
            width: 300,
        },



    },
    fullList: {
        width: "auto"
    },
    displayProperty: {
        display: "flex",
        justifyContent: "center"
    },
    listItemText: {

        // fontSize: 17,
        fontWeight: 600,
        [theme.breakpoints.up("xs")]: {
            fontSize: 14,
            marginLeft: -25,
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: 15,
            marginLeft: -25,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
            marginLeft: -20,
        }

    },
    logo: {
        height: 100,
        width: 100,
        marginBottom: 50,
        marginTop: 50,
        borderRadius: 5,

    },
    outerDiv: {
        display: "flex",
        justifyContent: "center"
    },
    listitems: {

        color: theme.palette.primary.main, //white
        [theme.breakpoints.up("xs")]: {
            marginTop: 10,
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: 13,
        }

    },
    drawerBackground: {
        background: '#ffffff', //theme.palette.primary.main
    },
    iconColor: {
        color: theme.palette.primary.main //white
    },
    dividerColor: {
        background: theme.palette.primary.main //white

    },
    txtname: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: -17,
        //   marginTop:-10
        [theme.breakpoints.up("xs")]: {
            marginTop: -20,
            fontSize: 18,
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: -20,
            fontSize: 20,
        }

    },
    txtemail: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 20,

        [theme.breakpoints.up("xs")]: {
            fontSize: 14,
            marginBottom: 14,
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: 15,
        }
    },
    bottomPush: {
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        paddingBottom: 10,


    },
    bottomListItem: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textAlign: 'center',
        [theme.breakpoints.up("xs")]: {
            fontSize: 14,
            marginLeft: 80,
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: 15,
            marginLeft: 80,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
            marginLeft: 100,
        }
    }
}));

const RightSideBar = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        props.openRightSidebar(open);

    };


    const logoutUser = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                console.log('logout successfull');
                clearCookie();
                history.push('/');

            })
            .catch((error) => {
                console.log('logout unsuccessfull', error);
            })
    }







    return (
        <div>
            {/* <Button onClick={toggleDrawer(true)}>Right</Button> */}
            <Drawer anchor={"right"}
                // open={false}   
                open={props.isOpen}
                onClose={toggleDrawer(false)}
                classes={{ paper: classes.drawerBackground }}>
                <div className={classes.outerDiv} >
                    <img alt='not able to display' src={logo} className={classes.logo} />
                </div>
                {
                    getLoginStatus() ?
                        <>
                            <h6 className={classes.txtname}>{getUsername() !== "null" ? getUsername() : ""}</h6>
                            <h6 className={classes.txtemail}>{getUserEmail()}</h6>
                        </> :
                        <></>
                }


                <Divider className={classes.dividerColor} />
                <div
                    className={`${classes.list} ${classes.displayProperty}`}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}>

                    <List>
                        {/* <ListItem button className={classes.listitems}>
                        <ListItemIcon className={classes.iconColor}>
                            <BookmarkIcon/>
                        </ListItemIcon>
                        <Typography variant='h6' className={classes.listItemText}>Bookmarks</Typography>
                    </ListItem>

                    <Divider className={classes.dividerColor}/> */}

                        <ListItem button className={classes.listitems}
                            onClick={() => {
                                // alert('HI, this is shailesh'+props.currentLanguageType);
                                props.toggleLanguage()
                            }}
                        >
                            <ListItemIcon className={classes.iconColor}>
                                <GTranslateIcon />
                            </ListItemIcon>
                            <Typography variant='h6' className={classes.listItemText}>Toggle Language</Typography>
                        </ListItem>

                        <Divider className={classes.dividerColor} />

                        {getLoginStatus() &&
                            <>
                                <ListItem button className={classes.listitems}
                                    onClick={() => {
                                        history.push("/user-profile")
                                    }}
                                >
                                    <ListItemIcon className={classes.iconColor}>
                                        <SupervisedUserCircleOutlined />
                                    </ListItemIcon>
                                    <Typography variant='h6' className={classes.listItemText}>Profile</Typography>
                                </ListItem>

                                <Divider className={classes.dividerColor} />
                            </>}


                        <ListItem button className={classes.listitems} onClick={() => {
                            window.open("mailto:contact@artdexandcognoscis.com");
                            // window.location.href = "mailto:user@example.com?subject=Subject&body=message%20goes%20here";
                        }}>
                            <ListItemIcon className={classes.iconColor}>
                                <HelpIcon />
                            </ListItemIcon>
                            <Typography variant='h6' className={classes.listItemText}>Help &amp; Feedback</Typography>
                        </ListItem>
                        <Divider className={classes.dividerColor} />


                        {
                            getLoginStatus() ?
                                <>
                                    <ListItem button className={classes.listitems} onClick={() => logoutUser()}>
                                        <ListItemIcon className={classes.iconColor}>
                                            <HelpIcon />
                                        </ListItemIcon>
                                        <Typography
                                            // onClick ={()=>logoutUser()}
                                            variant='h6' className={classes.listItemText}>Sign Out</Typography>
                                    </ListItem>
                                    <Divider className={classes.dividerColor} />
                                </> :
                                <>
                                    <ListItem button className={classes.listitems} onClick={() => history.push('/login')}>
                                        <ListItemIcon className={classes.iconColor}>
                                            <VerifiedUserIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6' className={classes.listItemText}>Sign In</Typography>
                                    </ListItem>
                                    <Divider className={classes.dividerColor} />
                                </>
                        }

                        <ListItem button className={classes.listitems} onClick={() => { history.push('/privacy-policy'); }}>
                            <ListItemIcon className={classes.iconColor} style={{ visibility: 'hidden' }}>
                                <HelpIcon />
                            </ListItemIcon>
                            <Typography variant='h6' className={classes.listItemText}>Privacy Policy</Typography>
                        </ListItem>
                        <Divider className={classes.dividerColor} />

                        {getLoginStatus() &&
                            <>
                                <ListItem button className={classes.listitems}
                                    onClick={() => {
                                        history.push("/user-notes")
                                    }}
                                >
                                    <ListItemIcon className={classes.iconColor}>
                                        <EventNote />
                                    </ListItemIcon>
                                    <Typography variant='h6' className={classes.listItemText}>Notes and Highlights</Typography>
                                </ListItem>

                                <Divider className={classes.dividerColor} />
                            </>}

                    </List>

                </div>

                {/* {
                    getLoginStatus() &&
                    <div className={classes.bottomPush} style={{}}>
                        <Divider style={{ marginLeft: '0px', marginRight: '-100px' }} className={classes.dividerColor} />
                        <div style={{ textAlign: 'center', paddingTop: 10, cursor: 'pointer' }} onClick={() => {
                            logoutUser()
                            props.openRightSidebar(false)
                        }}
                            variant='h6' className={classes.bottomListItem}>Sign Out</div>
                    </div>
                } */}

            </Drawer >
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        currentLanguageType: state.toggleLanguage.currentLanguageType,
        isOpen: state.openRightSidebar.isOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLanguage: () => dispatch(toggleLanguage()),
        openRightSidebar: (val) => dispatch(openRightSidebar(val))
    }
}


// export default RightSideBar

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RightSideBar)





