import {HOST} from '../../../config/config'
import axios from 'axios'
import { getToken } from '../../../config/cookiesInfo';

export const loginUser = (data) => {
    return new Promise((resolve, reject) => {

        axios.post(`${HOST}login/`,data)
        .then((res)=>{
            // console.log(res.data.results)
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
   
}

export const socialLogin = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${HOST}dj-rest-auth/google/`, data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
}

export const connectSocialLogin = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${HOST}dj-rest-auth/google/connect/`, data,
            {
                headers: {
                    "Authorization": `token ${getToken()}`
                },
            },)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
}